import React, { useEffect, useState } from "react";
import Header from "./HomeComp/Header";
import { div, round } from "./redux/helpers/Math";
import { useSelector } from "react-redux";
import {
  N_getHarvest,
  N_getRefStaking,
  N_getStake,
  N_getWithdraw,
  N_harvestRefStaking,
  N_setStake,
} from "./redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
import "./staking.css";
import "./stak.css";
import { IoCaretForwardOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";

const StakingOption = ({ props, period, interest, data, onStake, balance, iscallStake, isLoggedIn, enableStakingBtn, user, startStaking }) => {
  const [stakeAmount, setStakeAmount] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [stakeWithdrawData, setStakeWithdrawData] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isHarvest, setIsHarvest] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [withdrawcontroller, setWithdrawController] = useState(null);
  const handleStake = () => {
    if (stakeAmount > 999) {
      setShowConfirmation(true);
    } else {
      NotificationManager.error("Please Enter the Stake Amount Minimum 1000");
    }
  };

  const handleHideModal = () => {
    setModalShow(false)
    setShowTable(false)
  }

  const handleWithdraw = () => {
    setLoading(true);
    setShowTable(false);

    setTimeout(() => {
      const token = user?.params?.token || user.token;
      N_getWithdraw(token, period).then(d => {
        setLoading(false);
        if (d.status === 200) {
          setStakeWithdrawData(d.data);
          setShowTable(true);
          setModalShow(true)
          NotificationManager.success(d.message);
          startStaking();
        } else {
          NotificationManager.error(d.message);
        }
      });
    }, 2000);
  };

  const handleHarvest = (data) => {
    setIsHarvest(true)
    const token = user?.params?.token || user.token;
    // Abort any ongoing request
    if (withdrawcontroller) {
      withdrawcontroller.abort();
    }

    // Create a new controller for the new request
    const newController = new AbortController();
    setWithdrawController(newController);
    N_getHarvest(token, period, data.percent, data.total_ry, data.stakeId, newController).then(d => {
      if (d.status === 200) {
        NotificationManager.success(d.message);
        startStaking();
        setIsHarvest(false)
      } else {
        NotificationManager.error(d.message);
        setIsHarvest(false)
      }
    });
  };

  return (
    <div className="col-lg-3 col-md-6 col-sm-12">
      <div className="item-staking">
        <div className="header-item">
          <div className="logo-token">
            <img src="/favicon.ico" alt="logo-token" />
          </div>
        </div>
        <div className="content-item">
          <StakingInfo label="Staking Period" value={`${period}`} _mark="Days" />
          <StakingInfo label="Staking Interest" value={`${interest}`} _mark="%" />
          <StakingInfo label="Staking Amount" value={data?.total || 0} />
          {/* <StakingInfo label="Earned Amount" value={data?.stake_interest_earned || 0} /> */}
          <StakingInfo label="Maturity Earning" value={Math.floor(data?.MaturityEarning) || 0} />

          <WithdrawButton
            disabled={!data?.one_daily_ry}
            onClick={handleWithdraw}
          />

          {loading && (
            <div className="d-flex justify-content-center">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}

          {showTable && (
            <MyVerticallyCenteredModal show={modalShow} onHide={handleHideModal}
              handleHarvest={handleHarvest} stakeWithdrawData={stakeWithdrawData} isHarvest={isHarvest} />
          )}

          <StakeInput
            balance={balance}
            value={stakeAmount}
            onChange={(e) => setStakeAmount(e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1"))}
          />

          {isLoggedIn ? (
            <StakeButton onClick={handleStake} enableStakingBtn={enableStakingBtn} />
          ) : (
            <LoginButton onClick={() => props.history.replace("/login")} />
          )}

          <ConfirmationModal
            show={showConfirmation}
            onHide={() => setShowConfirmation(false)}
            onConfirm={() => onStake(stakeAmount, period)}
            iscallStake={iscallStake}
          />
        </div>
      </div>
    </div>
  );
};

const StakingInfo = ({ label, value, _mark }) => (
  <div className="wrap-amount-stake wrap-earn textmode">
    <span className="blur-text textmode">{label}: </span>
    <div className="token-earn">
      <span className="textmode">{round(value)} {_mark}</span>
    </div>
  </div>
);

function MyVerticallyCenteredModal({ show, onHide, handleHarvest, stakeWithdrawData, isHarvest }) {
  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Withdraw Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive my-3">
          <table className="table table-hover table-striped table-bordered">
            <thead className="table-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Total RY</th>
                <th scope="col">RY Status</th>
                <th scope="col">Total Investment</th>
                <th scope="col">Maturity Earning</th>
                <th scope="col">Total Interest Earned</th>
                <th scope="col">Remaining Interest</th>
                <th scope="col">Interest %</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody className="text-white">
              {stakeWithdrawData.map((data, index) => {
                return <tr key={data.stakeId}>
                  <th className="text-white" scope="row">{index + 1}</th>
                  <td className="text-white">{data.total_ry}</td>
                  <td>
                    <span className={`badge ${data.ry_status ? 'bg-success' : 'bg-warning text-dark'}`}>
                      {data.ry_status ? 'Completed' : 'Running'}
                    </span>
                  </td>
                  <td className="text-white">{data.total_investment}</td>
                  <td className="text-white">{Math.floor(data.MaturityEarning)}</td>
                  <td className="text-white">{data.total_interest_earned}</td>
                  <td className="text-white">{data.remaining_interest}</td>
                  <td className="text-white">{data.percent} %</td>
                  <td>
                  {!isHarvest?
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={(e) => {
                        e.preventDefault();
                        handleHarvest(data);
                      }}
                    >
                      Harvest
                    </button>:<div className="d-flex justify-content-center">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>}
                  </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const WithdrawButton = ({ disabled, onClick }) => (
  <div className="wrap-detail-and-harvest textmode">
    <div className="btn-show-detail">Withdraw Amount</div>
    <div className="harvest">
      <button
        disabled={disabled}
        type="button"
        className="ant-btn"
        style={{ cursor: "pointer" }}
        onClick={onClick}
      >
        <span>Withdraw</span>
      </button>
    </div>
  </div>
);

const StakeInput = ({ balance, value, onChange }) => (
  <div className="wrap-amount-stake textmode">
    <div className="input-stake-withdraw">
      <div className="balance-lp-and-staked">
        <div className="balance-lp">
          <span className="blur-text textmode">Available DTBX: </span>
          <span className="textmode balance-lp-amount" style={{ cursor: "pointer" }}>{balance}</span>
        </div>
      </div>
      <div className="input-amount">
        <div className="input-amount-unstakek">
          <div className="btn-max">More Deposit</div>
        </div>
        <div className="input-amount-stake">
          <input
            autoComplete="off"
            role="spinbutton"
            ariaValuemin="0.0001"
            step="1"
            placeholder="Enter Amount"
            value={value}
            className="ant-input-number-input"
            onChange={onChange}
          />
          {/* <div className="btn-max">DTBX</div> */}
        </div>
      </div>
    </div>
  </div>
);

const StakeButton = ({ onClick, enableStakingBtn }) => (
  <button
    type="button"
    disabled={enableStakingBtn}
    style={{ cursor: "pointer" }}
    id="first_stake"
    className="btn btn-success w-100"
    onClick={onClick}
  >
    <span>Stake</span>
  </button>
);

const LoginButton = ({ onClick }) => (
  <button
    type="button"
    style={{ cursor: "pointer" }}
    className="ant-btn ant-btn-primary ant-btn-lg btn-stake"
    onClick={onClick}
  >
    <span>Login</span>
  </button>
);

const ConfirmationModal = ({ show, onHide, onConfirm, iscallStake }) => (
  <Modal centered show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title className="text-center h6">
        <span>Stake Confirmation</span>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p className="text-center">By proceeding with the staking of your coins, you acknowledge and agree that the quantity of coins you stake will be locked and cannot be broken or withdrawn until the maturity period is completed</p>
    </Modal.Body>
    <Modal.Footer>
      <div className="row">
        <div className="col-6">
          <Button variant="secondary" block onClick={onHide}>Cancel</Button>
        </div>
        <div className="col-6">
          {!iscallStake?
          <Button variant="success" block onClick={onConfirm}>Confirm</Button>:<div className="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>}
        </div>
      </div>
    </Modal.Footer>
  </Modal>
);

const BannerStake = ({ dtbxinrprice, totalRefInterestEarned, totalLockedValue, totalInterestEarned, totalOldLockedValue, totalOldInterestEarned }) => {
  return (
    <div className="banner-stake">
      <div className="banner-stake--container">
        <div className="banner-stake__title">
          <div className="bg-shadow">
            <h3>DTBX Farm</h3>
            <div className="banner-stake__desc">Stake to earn more</div>
          </div>
        </div>
        <div className="banner-stake__right">
          <div className="banner-stake__price-wana">
            <div className="label">Current DTBX Price </div>
            <div className="value">
              <span>{round(dtbxinrprice)} ₹</span>
            </div>
          </div>

          {totalLockedValue ? <div className="banner-stake__total-lock">
            <div className="label">Total Value Locked</div>
            <div className="value">
              <span>{totalLockedValue && totalLockedValue}</span>
            </div>
          </div> : null}


          {totalInterestEarned ? <div className="banner-stake__total-lock">
            <div className="label" style={{ whiteSpace: "nowrap" }}>
              Staking Interest Total
            </div>
            <div className="value">
              <span>{totalInterestEarned && totalInterestEarned}</span>
            </div>
          </div> : null}

          {totalOldLockedValue ? <div className="banner-stake__total-lock">
            <div className="label">Total Value Locked ( old )</div>
            <div className="value">
              <span>{totalOldLockedValue && totalOldLockedValue}</span>
            </div>
          </div> : null}


          {totalOldInterestEarned ? <div className="banner-stake__total-lock">
            <div className="label" style={{ whiteSpace: "nowrap" }}>
              Staking Interest Total ( old )
            </div>
            <div className="value">
              <span>{totalOldInterestEarned && totalOldInterestEarned}</span>
            </div>
          </div> : null}

          {totalRefInterestEarned ? <div className="banner-stake__total-lock">
            <div className="label" style={{ whiteSpace: "nowrap" }}>
              Staking Referral Earnings
            </div>
            <div className="value">
              <span>
                {totalRefInterestEarned && totalRefInterestEarned}
              </span>
            </div>
          </div> : null}
        </div>
      </div>
    </div>
  );
};


function Staking(props) {
  const { user, isLoggedIn } = useSelector((state) => state.AuthReducer);
  let { coins, wallet, vhl_data } = useSelector((state) => state.coinDBReducer);
  let { webData } = useSelector((state) => state.websiteDBReducer);
  const token = user?.params?.token || user.token;
  const currentDate = new Date();
  const cutoffDate = new Date('2024-11-01');
  const [iscallStake, setIsStake] = useState(false);

  const [refUser, setRefUser] = useState(null);
  const [balance, setBalance] = useState(0);
  const [stakeData, setStakeData] = useState({});
  const [OldStakeData, setOldStakeData] = useState({});
  const [dtbxinrprice, setdtbxprice] = useState(null)
  const [totalLockedValue, setTotalLockedValue] = useState(0);
  const [totalOldLockedValue, setTotalOldLockedValue] = useState(0);
  const [totalInterestEarned, setTotalInterestEarned] = useState(0);
  const [totalOldInterestEarned, setTotalOldInterestEarned] = useState(0);
  const [totalRefInterestEarned, setTotalRefInterestEarned] = useState(0);
  const [stakecontroller, setStakeController] = useState(null);
  const [refcontroller, setrefController] = useState(null);
  const startStaking = () => {
    
  

    const stakingRates = {
      beforeCutoff: [
        { duration: 90, rate: [6] },
        { duration: 180, rate: [15] },
        { duration: 365, rate: [30] },
        { duration: 730, rate: [100] },
        { duration: 1825, rate: [300] },
      ],
      afterCutoff: [
        { duration: 90, rate: [4.5] },
        { duration: 180, rate: [12] },
        { duration: 365, rate: [26] },
        { duration: 730, rate: [84] },
        { duration: 1825, rate: [250] },
      ],
    };
    
    let stakingDurations = currentDate > cutoffDate ? stakingRates.afterCutoff : stakingRates.beforeCutoff;
    
   

    

    stakingDurations.forEach(({ duration, rate }) => {
      rate.forEach((r, index) => {
        N_getStake(token, duration).then((d) => {
          if (d.status === 200 || d.status === 300) {
            setStakeData(prev => ({ ...prev, [duration]: d.result }));
          }
        });
      });
    });
  };

  const handleStake = (amount, period) => {
    const token = user?.params?.token || user.token;
    if (stakecontroller) {
      stakecontroller.abort();
    }

    // Create a new controller for the new request
    const newController = new AbortController();
    setStakeController(newController);
    setIsStake(true);
    N_setStake(token, amount, period, newController).then(d => {
      if (d.status === 200) {
        NotificationManager.success(d.message);
        startStaking();
        setIsStake(false)

        // Set a timeout to reload the page after 2 seconds
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        NotificationManager.error(d.message);
        setIsStake(false);
      }
    });
  };

  useEffect(() => {
    if (user?.params?.token || user.token) startStaking();
  }, [user]);

  useEffect(() => {
    const dtbxData = wallet.find((obj) => obj.symbol === "DTBX");
    if (dtbxData) setBalance(dtbxData.balance);
  }, [wallet]);

  useEffect(() => {
    if (vhl_data) {
      const matchingData = vhl_data["dtbx-inr"];
      if (matchingData) setdtbxprice(matchingData.raw_price);
    }
  }, [vhl_data]);

  useEffect(() => {
    if (Object.values(stakeData).every((data) => data)) {
      const totalLocked = Object.values(stakeData).reduce((sum, data) => sum + round(data.total), 0);
      const totalInterest = Object.values(stakeData).reduce((sum, data) => sum + round(data.stake_interest_earned), 0);

      setTotalLockedValue(totalLocked);
      setTotalInterestEarned(totalInterest.toFixed(6));
    }

    if (Object.values(OldStakeData).every((data) => data)) {
      const totalLocked = Object.values(OldStakeData).reduce((sum, data) => sum + round(data.total), 0);
      const totalInterest = Object.values(OldStakeData).reduce((sum, data) => sum + round(data.stake_interest_earned), 0);

      setTotalOldLockedValue(totalLocked);
      setTotalOldInterestEarned(totalInterest.toFixed(6));
    }
  }, [stakeData, OldStakeData]);

  useEffect(() => {
    const token = user?.params ? user.params.token : user.token;

    N_getRefStaking(token).then((d) => {
      if (d.status === 200) {
        setRefUser(d.result);
        const TotalstakeInterestEarned = d.result.reduce(
          (sum, obj) => sum + obj.stakeInterestEarned,
          0
        );
        setTotalRefInterestEarned(round(TotalstakeInterestEarned).toFixed(6));
      }
    });
  }, []);

  const stakingOptionsBeforeCutoff = [
    { period: 90, interest: 6 },
    { period: 180, interest: 15 },
    { period: 365, interest: 30 },
    { period: 730, interest: 100 },
    { period: 1825, interest: 300 },
  ];
  
  const stakingOptionsAfterCutoff = [
    { period: 90, interest: 4.5 },
    { period: 180, interest: 12 },
    { period: 365, interest: 26 },
    { period: 730, interest: 84 },
    { period: 1825, interest: 250 },
  ];
  
  const stakingOptions = currentDate > cutoffDate ? stakingOptionsAfterCutoff : stakingOptionsBeforeCutoff;
  


  return (
    <>
      <div className="application">
        <Helmet>
          <meta
            name="description"
            content="Explore the DTBX market for seamless and secure crypto trading. Dive
into the world of DTBX Crypto Trading and elevate your trading experience. "
          />
          <title>Unlock DTBX Staking Rewards in DTBX Staking Pool</title>
        </Helmet>
      </div>

      <Header {...props} />

      <div className="page content">
        {/* <div className="d-flex justify-content-end mx-4">
          <NavLink to="/staking-history">
            <button className="btn btn-primary d-flex align-items-center">
              <span>History</span>
              <IoCaretForwardOutline size={25} className="mr-2" />
            </button>
          </NavLink>
        </div> */}
        <div className="staking-view background-mode">
          <div className="stak-container">
            <BannerStake
              dtbxinrprice={dtbxinrprice}
              totalLockedValue={totalLockedValue}
              totalInterestEarned={totalInterestEarned}
            />

            <div className="list-staking">
              <div className="row ant-row-center" style={{ rowGap: "30px", display: "flex", flexDirection: "row" }}>

                <div className="col col-md-12 col-sm-12 banner-stake" style={{ height: "100px", background: "#60a7ab" }}>
                  <div className="banner-stake--container">
                    <div className="">
                      <div className="bg-shadow">
                        <h3>DTBX Farm Minimum Stake 1000 DTBX</h3>
                      </div>
                    </div>
                  </div>
                </div>
                {stakingOptions.map(option => (
                  <StakingOption
                    key={option.period}
                    period={option.period}
                    interest={option.interest}
                    data={stakeData[option.period]}
                    onStake={handleStake}
                    balance={balance}
                    iscallStake={iscallStake}
                    isLoggedIn={isLoggedIn}
                    user={user}
                    startStaking={startStaking}
                  />
                ))}

                {/* ------------------- */}
                {/* <BannerStake
                  dtbxinrprice={dtbxinrprice}
                  totalOldLockedValue={totalOldLockedValue}
                  totalOldInterestEarned={totalOldInterestEarned}
                />
                <div className="col col-md-12 col-sm-12 banner-stake" style={{ height: "100px", background: "#60a7ab" }}>
                  <div className="banner-stake--container">
                    <div className="">
                      <div className="bg-shadow">
                        <h3>DTBX Farm Minimum Stake 1000 DTBX</h3>
                      </div>
                    </div>
                  </div>
                </div>
                {OldStakingOptions.map(option => {
                  return <StakingOption
                    key={option.period}
                    period={option.period}
                    interest={option.interest}
                    data={OldStakeData[option.period]}
                    onStake={handleStake}
                    balance={balance}
                    isLoggedIn={isLoggedIn}
                    enableStakingBtn={false}
                    user={user}
                    startStaking={startStaking}
                  />
                })} */}


                {/* ------------------- */}
                <BannerStake
                  dtbxinrprice={dtbxinrprice}
                  totalRefInterestEarned={totalRefInterestEarned}
                />
                {refUser && refUser.length > 0 ? (
                  <div className="list-staking">
                    <div
                      className="row ant-row-center"
                      style={{
                        rowGap: "30px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <div
                        className="col col-md-12 col-sm-12 banner-stake"
                        style={{ height: "100px", background: "#60a7ab" }}
                      >
                        <div className="banner-stake--container">
                          <div className="">
                            <div className="bg-shadow">
                              <h3>DTBX Referral Farm</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* --------------------------------------------------- */}
                      {/* referal user staking */}
                      <div className="mt-3">
                        <div className="card">
                          <div className="card-body">
                            {/* <div className="row mt-2 mb-2">
                              <h6 className="col-md-12 page-title">
                                Your Referral Users Staking Details
                              </h6>
                              <div className="d-flex justify-content-center">
                                <div className="text-center bg-light text-dark px-2 py-2 rounded-2">
                                  <span className="textmode balance-lp-amount">
                                    {balance}
                                  </span>
                                </div>
                              </div>
                            </div> */}

                            <div className="overflow-auto mt-3">
                              <div className="table_scroll_div">
                                <table className="table global_table table-bordered">
                                  <thead className="bg-light">
                                    <tr>
                                      <th className="tdCenter">NO.</th>
                                      <th className="tdCenter">User</th>
                                      <th className="tdCenter">Staked</th>
                                      <th className="tdCenter">Staking Days</th>
                                      <th className="tdCenter">
                                        Staking Percentage
                                      </th>
                                      <th className="tdCenter">
                                        Type
                                      </th>
                                      <th className="tdCenter">Interest Earned</th>
                                      <th className="tdCenter">Maturity Date</th>
                                      <th className="tdCenter">
                                        Withdraw Interest
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {refUser && refUser.length > 0
                                      ? refUser.map((result, key) => {
                                        return (
                                          <tr>
                                            <td className="tdCenter">
                                              {key + 1}
                                            </td>
                                            <td className="tdCenter">
                                              {result.user_credential
                                                ? result.user_credential
                                                : 0}
                                            </td>
                                            <td className="tdCenter">
                                              {result.total_invested_dtbx
                                                ? round(
                                                  result.total_invested_dtbx
                                                )
                                                : 0}
                                            </td>
                                            <td className="tdCenter">
                                              {result.invest_time
                                                ? result.invest_time
                                                : 0}
                                            </td>
                                            <td className="tdCenter">
                                              {result.percent
                                                ? result.percent
                                                : 0}{" "}
                                              %
                                            </td>
                                            <td className="tdCenter">
                                              {result?.type=="affliate"?"Affiliate":"Referral"}
                                            </td>
                                            <td className="tdCenter">
                                              {" "}
                                              {result.total_ry
                                                ? round(result.total_ry)
                                                : 0}
                                            </td>
                                            <td className="tdCenter">
                                              {" "}
                                              {result.timeEx
                                                ? new Date(
                                                  result.timeEx
                                                ).toLocaleDateString()
                                                : 0}
                                            </td>
                                            <td>
                                              <>
                                                <div className="harvest">
                                                  <button
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      const token = user?.params
                                                        ? user.params.token
                                                        : user.token;
                                                      const invest_time =
                                                        result.invest_time; // days
                                                      const invest_amount = result.total_invested_dtbx
                                                      if (result.total_ry > 0) {
                                                        if (refcontroller) {
                                                          refcontroller.abort();
                                                        }
                                                    
                                                        // Create a new controller for the new request
                                                        const newController = new AbortController();
                                                        setrefController(newController);
                                                        N_harvestRefStaking(
                                                          token,
                                                          result.user_id,
                                                          invest_time,
                                                          round(result.total_ry),
                                                          invest_amount,
                                                          result?.type,
                                                          newController
                                                        ).then((d) => {
                                                          if (d.status === 200) {
                                                            N_getRefStaking(
                                                              token
                                                            ).then((d) => {
                                                              if (
                                                                d.status === 200
                                                              ) {
                                                                setRefUser(
                                                                  d.result
                                                                );
                                                              }
                                                            });
                                                            NotificationManager.success(
                                                              d.message
                                                            );
                                                          } else {
                                                            NotificationManager.error(
                                                              d.message
                                                            );
                                                          }
                                                        });
                                                      } else {
                                                        NotificationManager.info(
                                                          "You Have not Earn"
                                                        );
                                                      }
                                                    }}
                                                    className="ant-btn"
                                                  >
                                                    <span>Withdraw</span>
                                                  </button>
                                                </div>
                                              </>
                                            </td>
                                          </tr>
                                        );
                                      })
                                      : null}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

              </div>
            </div>


          </div>
        </div>
      </div>
    </>
  );
}

export default Staking;
