import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoginORSignup from "./LoginORSignup";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import "./order.css";
import Loader from "./Loader";
import { getUserBalance, getUserOrder } from "../redux/actions/coinDBAction";
import {
  N_cancleOrderById,
  N_pendingOrder,
  N_completeOrder,
} from "../redux/helpers/api_functions_new";
import { round } from "../redux/helpers/Math";
import {
  SET_USER_ORDER_CLOSE,
  SET_USER_ORDER_PENDING,
} from "../redux/constant";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from 'react-router-dom'
export default function OrdersTab({ isLoggedIn, user, user_order_pending, user_order_close }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const [pendingOrder, setpendingOrder] = useState([]);
  const [closeOrder, setcloseOrder] = useState([]);

  useEffect(() => {
    if (activeTab == 0) {
      N_pendingOrder(user?.params ? user.params.token : user.token)
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: SET_USER_ORDER_PENDING,
              data: res.params.trade_history.pending,
              pending_order_loading: false,
            });
          } else {
            console.log("error to fetch open and close orders: ", res);
          }
        })
        .catch((e) => console.log(e));
    }
    if (activeTab == 1) {
      N_completeOrder(user?.params ? user.params.token : user.token)
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: SET_USER_ORDER_CLOSE,
              data: res.params.trade_history.compleated,
              close_order_loading: false,
            });
          } else {
            console.log("error to fetch open and close orders: ", res);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [activeTab]);

  useEffect(() => {
    setpendingOrder(
      user_order_pending.sort(
        (a, b) => Number(b.timestamp) - Number(a.timestamp)
      )
    );
    setcloseOrder(
      user_order_close.sort((a, b) => Number(b.timestamp) - Number(a.timestamp))
    );
  }, [user_order_pending, user_order_close]);

  return (
    <>
      <div className="card mt-2 mb-2">
        <div className="card-header bg-light p-0">
          <div className="nav nav-pills" id="nav-tab" role="tablist">
            <div
              className={`nav-item nav-link  ${activeTab === 0 ? "active" : ""
                }`}
              id="nav-home-tab"
              data-toggle="tab"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
              onClick={() => setActiveTab(0)}
            >
              Open Orders
            </div>
            <div
              className={`nav-item nav-link ${activeTab === 1 ? "active" : ""}`}
              id="nav-profile-tab"
              data-toggle="tab"
              onClick={() => setActiveTab(1)}
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              <div className="d-flex justify-content-between align-items-center">
                <span className="mx-2">Completed Orders</span>
                <button onClick={() => {
                  history.push('/complete-order-history')
                }} disabled={activeTab !== 1} className="btn btn-sm btn-primary">View</button>
              </div>
            </div>
          </div>
        </div>

        <div className="order_height custom_scrollbar">
          {!isLoggedIn ? (
            <div className="tab-content orders">
              <LoginORSignup />
            </div>
          ) : null}
          {activeTab === 0 && isLoggedIn ? (
            <div className="">
            </div>
          ) : null}
          {isLoggedIn ? (
            <>
              <div className="tab-content orders">
                <div
                  className={`tab-pane fade ${activeTab === 0 ? "show active" : ""
                    }`}
                  id="open-order"
                >
                  <table className="exchange_tbl order-book-table ">
                    <thead className="sticky_thead">
                      <tr>
                        <th>
                          <h6>Pair</h6>
                        </th>
                        <th>
                          <h6>Amount</h6>
                        </th>
                        <th>
                          <h6>Price</h6>
                        </th>
                        <th>
                          <h6>Total</h6>
                        </th>
                        <th>
                          <h6>Executed</h6>
                        </th>
                        <th>
                          <h6>Completed</h6>
                        </th>
                        <th>
                          <h6>Delete</h6>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {pendingOrder && pendingOrder?.length > 0
                        ? pendingOrder.map((d, index) => {
                          return (
                            <OrderRow
                              {...d}
                              key={index}
                              user_id={
                                user?.params ? user.params.token : user.token
                              }
                            />
                          );
                        })
                        : null}
                    </tbody>
                  </table>

                  {pendingOrder?.length === 0 ? (
                    <div className="text-center py-5 text-muted" colspan="3">
                      <div>
                        <i class="fa fa-folder-open-o fs-2 text-muted"></i>
                      </div>
                      <div className="fs-12">No Open Orders !</div>
                    </div>
                  ) : null}
                  {loading ? <Loader /> : null}
                </div>

                <div
                  id="order-history"
                  className={`tab-pane fade ${activeTab === 1 ? "show active" : ""
                    }`}
                >
                  <table className="order-book-table exchange_tbl">
                    <thead className="sticky_thead">
                      <tr>
                        <th>
                          <h6>Pair</h6>
                        </th>
                        <th>
                          <h6>Amount</h6>
                        </th>
                        <th>
                          <h6>Price</h6>
                        </th>
                        <th>
                          <h6>Total</h6>
                        </th>
                        <th>
                          <h6>Executed</h6>
                        </th>
                        <th>
                          <h6>Completed</h6>
                        </th>
                        <th>
                          <h6>Action</h6>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {closeOrder && closeOrder?.length > 0
                        ? closeOrder.map((d, index) => {
                          return (
                            <OrderRow {...d} key={index} order_type={1} />
                          );
                        })
                        : null}
                    </tbody>
                  </table>
                  {closeOrder?.length === 0 ? (
                    <div className="text-center py-5 text-muted" colspan="3">
                      <div>
                        <i class="fa fa-folder-open-o fs-2 text-muted"></i>
                      </div>
                      <div className="fs-12">No Completed Orders !</div>
                    </div>
                  ) : null}
                  {loading ? <Loader /> : null}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}

function OrderRow(props) {
  const [popup, setpopup] = useState(false);
  const [spin, setspin] = useState("");
  const [controller, setController] = useState(null);
  const handleDeleteClick = (event) => {
    setpopup(true);
  };

  const dispatch = useDispatch();
  let progress_width = 0;
  let back = "rgb(16 129 53 / 10%)";
  const [dis, setdis] = useState(false);
  if (props["total_buy"] === undefined) {
    progress_width = (props.total_executed * 100) / props.volume;
    back = props.type === "buy" ? "rgba(35, 172, 80, 0.4)" : "#81101026";
  } else {
    progress_width = (props.total_executed * 100) / props.volume;
    back = "rgb(16 129 53 / 10%)";
  }

  const deleteOrder = (order_id, token) => {
     // Abort any ongoing request
     if (controller) {
      controller.abort();
    }

    // Create a new controller for the new request
    const newController = new AbortController();
    setController(newController);
    setspin("spinner-border spinner-border-sm");
    N_cancleOrderById(token, order_id, newController)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getUserOrder(token, props.type));
          dispatch(getUserBalance(token));
          setTimeout(() => {
            setspin("");
            setpopup(false);
            setdis(false);
          }, 1000);
          // setLoading(false);
          NotificationManager.success(res.message);
        } else {
          NotificationManager.error(res.message);
        }
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  };

  const handleClose = () => setpopup(false);

  return (
    <>
      {popup ? (
        <Modal size="sm" centered show={popup} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title className="text-center h6">
              Delete Confirmation
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="text-center">Are you sure you want to delete?</p>
          </Modal.Body>
          <Modal.Footer>
            <div className="row">
              <div className="col-6">
                <Button variant="secondary" block onClick={handleClose}>
                  Cancel
                </Button>
              </div>
              <div className="col-6">
              {dis ? (
                    <span
                      className={`${spin} mx-2`}
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) :   <Button
                  variant="danger"
                  block
                  onClick={() => {
                    if (!dis) {
                      setdis(true);
                      deleteOrder(
                        props.order_id,
                        props.user_id,
                        props["total_buy"] === undefined ? "sell" : "buy"
                      );
                    }
                  }}
                >
                  
                  Confirm
                </Button>}
              
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      ) : null}

      <tr className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>
        <td className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>
          {props?.currency_type?.toUpperCase()}
        </td>
        <td className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>
          {round(props?.volume)}
        </td>
        <td className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>
          {round(props?.raw_price)}
        </td>
        <td className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>
          {round(props?.volume * props?.raw_price)}
        </td>
        <td className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>
          {props.total_executed}
        </td>
        <td className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>
          {props?.order_type !== 1 ? (
            <span className="">
              {new Date(Number(props.timestamp)).toLocaleString()}
            </span>
          ) : (
            <span className="">
              {new Date(Number(props.timestamp)).toLocaleString()}
            </span>
          )}
        </td>

        <td className={` ${props.type === "buy" ? "text-green" : "text-red"}`}>
          {props?.order_type !== 1 ? (
            <div title="Cancel Order" className="" onClick={handleDeleteClick}>
              <i className="fa fa-trash text-danger"></i>
            </div>
          ) : props?.status !== "c" ? (
            <span className="">Cancel</span>
          ) : (
            <span className="">Executed</span>
          )}
        </td>
      </tr>
    </>
  );
}
