import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { getCoinRate, getRound } from "./redux/helpers/helper_functions";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { N_DepositUpdate, N_transferAmonut } from "./redux/helpers/api_functions_new";

export default function FundingMain() {
  const { wallet, paired_curency_price, coins, wallet_loading } = useSelector(
    (state) => state.coinDBReducer
  );
  const { user  } = useSelector((state) => state.AuthReducer);
  const [total_inr, setTotalInr] = useState(0);
  const [total_usdt, setTotalUsdt] = useState(0);
  const [total_btc, setTotalBtc] = useState(0);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [symbol, setSymbol] = useState('');
  const [amount, setAmount] = useState(0);
  const [hide, setHide] = useState(true)
  const [search, setSearch] = useState('');
  const [controller, setController] = useState(null);
  const [wallet_details, setwalletDetails] = useState([]);
  useEffect(()=>{
    if (!wallet_loading) {
      getWallets();
    }
  }, [wallet, coins, paired_curency_price])
  function getWallets() {
      let total = 0;
      let final_data = wallet && Object.keys(wallet).map((res, i) => {
        let wallet_type = wallet[res]?.symbol.toUpperCase();
        let rate = getCoinRate(coins, wallet_type);
        let inr_val =rate*(getRound(wallet[res]?.balance));
        let inr_available = rate*(getRound(wallet[res]?.balance-wallet[res]?.locked));
        let inr_locked =rate*(getRound(wallet[res]?.locked));
        total = total + parseFloat(inr_val);
        return {
          id: wallet[res]?.id,
          deposit_limit: wallet[res]?.deposit_limit
            ? wallet[res].deposit_limit
            : 0,
          icon: wallet[res]?.icon,
          symbol: wallet_type,
          name: wallet[res]?.name,
          status: wallet[res]?.status,
          withdral_fee: wallet[res]?.withdrawal_fee,
          locked:
            Math.round(wallet[res]?.locked * 10000) / 10000 != 0
              ? Math.round(
                  Math.abs(
                    wallet[res]?.locked
                      ? wallet[res]?.locked
                      : 0
                  ) * 10000
                ) / 10000
              : Math.round(wallet[res]?.locked * 100000000) /
                100000000,
          address: wallet[res]?.wallet_address,
          balance:
            Math.round(wallet[res]?.balance * 10000) / 10000 != 0
              ? Math.round(wallet[res]?.balance * 10000) / 10000
              : Math.round(wallet[res]?.balance * 100000000) /
                100000000,
          p2pbalance: wallet[res]?.p2p_balance
            ? wallet[res]?.p2p_balance
            : 0,
          avl_balance:
            Math.round(wallet[res]?.available * 10000) / 10000 !=
            0
              ? Math.round(
                  (wallet[res]?.balance -
                    wallet[res]?.locked) *
                    10000
                ) / 10000
              : Math.round(
                  (wallet[res]?.balance -
                    wallet[res]?.locked) *
                    100000000
                ) / 100000000,
          inr_total_balance: getRound(inr_val),
          inr_avail_balance: getRound(inr_available),
          inr_locked_balance: getRound(inr_locked),
        };
      });
      setwalletDetails(final_data);
      setTotalInr(getRound(total));
      setTotalUsdt(getRound(total/paired_curency_price.USDT))
      setTotalBtc(getRound(total/paired_curency_price.BTC));
  }
  const coin_data = coins && Object.values(coins).map((item)=>{
    return (
      <option value={item.symbol}>{item.symbol}</option>
    )
  })

  useEffect(()=>{
    getUpdate();
  }, [])


  const getUpdate = () =>{
     // Abort any ongoing request
     if (controller) {
      controller.abort();
    }

    // Create a new controller for the new request
    const newController = new AbortController();
    setController(newController);
    N_DepositUpdate(user?.params ? user.params.token : user.token, newController)
  }


  const walletsdata = wallet_details && wallet_details.filter((products)=>{
      return products.symbol.includes(search);
  })
  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="container">
            <div className="marginTop">
              <div className="account_page">
                <div className="page-content-title">
                  <h3 className="">Funding Account</h3>
                </div>

                <div className="col-lg-12">
                  <div className="flex_row mb-4">
                    <div className="">
                      <div class="">
                        <span class="desc_card">{total_inr}</span>
                        <span class="fs-16 text-muted ms-1">INR</span>
                        <span class="fs-16 text-lgray">
                          <span>≈ {total_usdt}</span>
                          <span>USD</span>
                        </span>
                        <span class="fs-16 text-pink ms-2">
                          <span class="interests_SddH3">(Equity:</span>
                          <span>{total_btc} BTC</span>)
                        </span>
                      </div>
                    </div>
                    <div className="">
                      {/* <Link to="/profile" className="btn btn-light text-pink">
                        Account Details
                      </Link> */}
                      <button
                      className="btn btn-danger"
                      onClick={(e)=>{
                        getUpdate();
                      }}
                      >
                        Refresh Fund
                      </button>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="row">
                    <div className="activity_panel mb-4">
                      <div className="search_input_grp">
                        <div class="input-group">
                          <span class="input-group-text">
                            <i class="fa-solid fa-magnifying-glass"></i>
                          </span>
                          <input
                            type="text"
                            placeholder="Search"
                            class="form-control text-start"
                            onChange={(e)=>{
                              setSearch(e.target.value.toUpperCase())
                            }}
                          />
                        </div>
                      </div>

                      <div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="inlineCheckbox1"
                            checked={hide}
                            onClick={(e)=>{
                              setHide(!hide);
                            }}
                          />
                          <label class="form-check-label" for="inlineCheckbox1">
                            Hide small balances
                          </label>
                        </div>
                        {/* <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="inlineCheckbox2"
                            value="option2"
                          />
                          <label class="form-check-label" for="inlineCheckbox2">
                            KuCoin Earn assets
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="alert alert-success">
                      <div className="d-flex">
                        <div>
                          <i class="fa-regular fa-circle-check text-green me-2"></i>
                        </div>
                        <div className="text-lighter">
                          {" "}
                          To trade tokens, click Transfer to move the assets
                          from your Funding Account to your Trading Account.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="overflow-auto mt-3">
                  <div className="table_scroll_div">
                    <table className="table global_table">
                      <thead>
                        <tr>
                          <th>Coin</th>
                          <th>Total</th>
                          <th>Available Amount</th>
                          <th>In Orders</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>


                        {walletsdata ? walletsdata.map((item)=>{
                          if(!hide || item.balance>0)
                          return(
                            <tr>
                            <td>
                              <div class="d-flex align-items-center">
                                <div>
                                  <img
                                    src={item.icon}
                                    alt="coin symbole"
                                    class="thumb_img"
                                  />
                                </div>
                                <div class="product_name">
                                  <div class="mb-0 fw-bold">{item.symbol}</div>
                                  <div class="text-muted fs-12"> {item.name}</div>
                                </div>{" "}
                              </div>
                            </td>
                            <td>
                              <div class="mb-0 fw-bold">{item.balance}</div>
                              <div class="text-muted fs-12">{item.symbol !='INR'?"≈ "+item.inr_total_balance:""} INR</div>
                            </td>
                            <td>
                              <div class="mb-0 fw-bold">{item.avl_balance}</div>
                              <div class="text-muted fs-12">{item.symbol !='INR'?"≈ "+item.inr_avail_balance:""} INR</div>
                            </td>
                            <td>
                              <div class="mb-0 fw-bold">{item.locked}</div>
                              <div class="text-muted fs-12">{item.symbol !='INR'?"≈ "+item.inr_locked_balance:""} INR</div>
                            </td>
  
                            <td>
                              <Link to="/AssetsDeposit" class="anchor_link text-green">
                                Deposit
                              </Link>
                              <div class="verticle_line"></div>
                              <Link to="/AssetsWithdraw" class="anchor_link text-green">
                                Withdraw
                              </Link>
                              <div class="verticle_line"></div>
                              {/* <a 
                              class="anchor_link text-green"
                              data-bs-toggle="modal"
                              data-bs-target="#transfer_modal"
                              >
                                Transfer
                              </a> */}
                              {/* <a href="/exchange/aco-inrx" class="anchor_link">
                                Trade
                              </a> */}
                            </td>
                          </tr>
                          )
                        }):<tr>
                        <td
                          colSpan={9}
                          className="text-center border-bottom-0 text-muted"
                        >
                          <img
                            src="/img/no-data.png"
                            className="no-data-found"
                          />
                          <p>No records</p>
                        </td>
                      </tr>}
                      
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div class="modal fade" tabindex="-1" id="transfer_modal">
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Transfer</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
            <label className="">from</label>
             <select className="form-control"
             onChange={(e)=>{
              setFrom(e.target.value);
             }}>
             <option selected> Please Select</option>
              <option value="funding">Funding Account</option>
             </select>
             <label className="">to</label>
             <select className="form-control"
             onChange={(e)=>{
              setTo(e.target.value);
             }}>
              <option selected> Please Select</option>
              <option value="p2p">p2p Account</option>
              <option value="margin">margin Account</option>
              <option value="future">future Account</option>
             </select>
             <label className="">Coin</label>
             <select className="form-control"
             onChange={(e)=>{
              setSymbol(e.target.value);
             }}>
             <option selected> Please Select</option>
              {coin_data}
             </select>
             <label className="">Amount</label>
             <input className="form-control" type="number" 
             onChange={(e)=>{
              setAmount(e.target.value)
             }}/>
             <div className="d-flex">
             <button className="btn btn-pink"
              data-bs-dismiss="modal"
              aria-label="Close"
             onClick={(e)=>{
              e.preventDefault()
              if(from =='funding' && to && symbol && amount>0) {
                let type = 'add';
                let wallet_type = symbol;
                let volume = amount;
                let action = to;
                N_transferAmonut(type, wallet_type, user?.params ? user.params.token : user.token, volume, action)
                  .then((data)=>{
                    if(data.status == 200) {
                      NotificationManager.success(data.message)
                      setTimeout(()=>{
                        document.location.reload();
                      }, 2000)
                      
                    } else {
                      NotificationManager.error(data.message);
                    }
                  });
              } else {
                NotificationManager.error("Fill All Data");
              }
              
             }}
             >Confirm</button>
             </div>
            
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}
